<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Редактирование товара</div>
                <div class="page__desc">Измените данные товара</div>
            </div>
        </div>

        <div class="page__content" v-if="product != null">
            <form
                    v-if="product !== null"
                    @submit.prevent="update()"
                    class="form-group"
                    method="POST"
                    action=""
                    enctype="multipart/form-data"
                    role="form">

                <label for="title">Название</label>
                <div class="control-group">
                    <input id="title" type="text" name="title" class="form-control" v-model="product.title" required>
                </div>
                <br>

                <label for="photo">Фото</label><br>
                <img v-if="product.photo.url !== null" class="img-thumbnail" :src="product.photo.url">
                <div class="control-group">
                    <input ref="photo" id="photo" type="file" name="photo" class="form-control">
                </div>
                <br>

                <label for="category_id">Категория</label>
                <div class="control-group">
                    <select
                            v-model="product.category_id"
                            id="category_id"
                            name="category_id"
                            class="form-control"
                            required>

                        <template
                            v-for="cc in categoriesTree">

                            <optgroup
                                    v-bind:key="cc.id"
                                    :label="cc.title">

                                <option v-for="c in cc.categories"
                                        v-bind:key="c.id"
                                        :value="c.id"
                                        :selected="product.category_id === c.id">{{ c.title }}</option>
                            </optgroup>
                        </template>
                    </select>
                </div>
                <br>

                <label for="price">Цена</label>
                <div class="control-group">
                    <input id="price" type="text" name="price" class="form-control" v-model="product.price.normal" placeholder="10.00" required>
                </div>
                <br>

                <div class="row">
                    <div class="col-sm-4">
                        <label for="weight_value">Вес товара</label>
                        <div class="control-group">
                            <input id="weight_value" type="text" class="form-control" v-model="product.weight.value" placeholder="1000" required>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label for="weight_type">Вес товара</label>
                        <div class="control-group">
                            <select id="weight_type" class="form-control" v-model="product.weight.type" required>
                                <option value="GRAM">GRAM</option>
                                <option value="GRAM">ML</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label for="packaging_type">Фасовка</label>
                        <div class="control-group">
                            <select id="packaging_type" class="form-control" v-model="product.packaging.type" required>
                                <option value="PIECE">Штучный</option>
                                <option value="WEIGHT">Весовой</option>
                            </select>
                        </div>
                    </div>
                </div>
                <br>

                <label for="manufacturer_id">Производитель</label>
                <div class="control-group">
                    <model-select :options="manufacturersOptions"
                                  v-model="product.manufacturer_id"
                                  id="manufacturer_id"
                                  class="form-control"
                                  required>
                    </model-select>
                </div>
                <br>

                <label for="barcode">Штрихкод</label>
                <div class="control-group">
                    <input id="barcode" type="text" name="barcode" class="form-control" v-model="product.barcode">
                </div>
                <br>

                <label for="storage_conditions">Условия хранения (℃)</label>
                <div class="control-group">
                    <textarea id="storage_conditions" type="text" name="storage_conditions" class="form-control" placeholder="При температуре от +5℃ до +7℃ градусов" v-model="product.storage_conditions"></textarea>
                </div>
                <br>

                <label for="ingredients">Состав</label>
                <div class="control-group">
                    <textarea id="ingredients" type="text" name="ingredients" class="form-control" placeholder="100% апельсин" v-model="product.ingredients"></textarea>
                </div>
                <br>

                <label for="is_hit"><input type="checkbox" id="is_hit" name="is_hit" v-model="product.is_hit"> Бестселлер</label>
                <br><br>

                <input type="submit" class="btn btn-success btn-large" value="Сохранить">

            </form>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'
    import { ModelSelect } from 'vue-search-select'
    import 'vue-search-select/dist/VueSearchSelect.css'

    export default {
        name: 'CatalogProductUpdate',
        components: {
            ModelSelect,
        },
        computed: {
            categoriesTree() {
                return this.$store.state.catalog.categoriesTree
            },
            product() {
                return this.$store.state.products.product
            },
            manufacturers() {
                return this.$store.state.manufacturers.manufacturers != null ? this.$store.state.manufacturers.manufacturers.items : [];
            },
            manufacturersOptions() {
                let options = [];
                for (let i=0; i<this.manufacturers.length; i++) {
                    options.push({
                        value: this.manufacturers[i].id,
                        text: this.manufacturers[i].title,
                    })
                }
                return options;
            }
        },
        methods: {
            ...mapActions([
                'catalogCategoriesTree',
                'catalogProductGet',
                'catalogProductUpdate',
                'catalogManufacturers',
            ]),
            async getProduct() {
                await this.catalogProductGet({
                    id: this.$route.params.id,
                })
            },
            async getCategoriesTree() {
                await this.catalogCategoriesTree()
            },
            async getManufacturers() {
                await this.catalogManufacturers()
            },
            async update() {
                const formData = new FormData();
                formData.append("photo", this.$refs.photo.files[0]);
                formData.append("id", this.product.id);
                formData.append("title", this.product.title);
                formData.append("price", this.product.price.normal);
                formData.append("weight_type", this.product.weight.type);
                formData.append("weight_value", this.product.weight.value);
                formData.append("packaging_type", this.product.packaging.type);
                formData.append("packaging_value", this.product.packaging.type === "PIECE" ? "БР" : "КГ");
                formData.append("category_id", this.product.category_id);
                formData.append("manufacturer_id", this.product.manufacturer_id);
                formData.append("barcode", this.product.barcode);
                formData.append("is_hit", this.product.is_hit);
                if (this.product.ingredients != null) {
                    formData.append("ingredients", this.product.ingredients);
                }
                if (this.product.storage_conditions != null) {
                    formData.append("storage_conditions", this.product.storage_conditions);
                }

                await this.catalogProductUpdate(formData)
            }
        },
        created() {
            this.getProduct();
            this.getCategoriesTree();
            this.getManufacturers();
        },
    }
</script>